.overlay {
    position: fixed;
    z-index: 90;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.8;
}

.dialog {
    position: fixed;
    background-color: #d8d8d8;
    width: 570px;
    z-index: 100;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin: 0 auto;
    padding: 20px;
    transform: translate(-50%, -50%);
    border-radius: 5px;
}

.box {
    background-color: #fff;
    padding: 10px;
}

.download {
    text-align: center;
}

.download button {
    margin: 5px 5px 0;
}