.sortCriteria {
    text-align: center;
}

.pageNavi {
    text-align: right;
}

.pageNaviItem {
    margin: 0 5px;
}