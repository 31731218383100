.previewBox {
    text-align: center;
    margin: 10px;
}

.previewBox::after {
    content: "";
    display: block;
    clear: both;
}

.preview {
    width: 200px;
    margin: 0 auto;
    float: left;
    text-align: center;
}

.preview caption {
    width: 200px;
    margin: 5px;
    font-size: 80%;
}