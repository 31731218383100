.downloadButton {
    display: inline-block;
    padding: 7px 20px;
    text-decoration: none !important;
    font-weight: normal !important;
    background: #f0f0f0;
    color: #000 !important;
    border: solid 1px #e0e0e0;
    box-shadow: 2px 2px #bbbbbb;
    border-radius: 5px;
}

.downloadButton:hover {
    background: #e8e8e8 !important;
    border: solid 1px #cccccc;
}

.downloadButton:active {
    transform: translate(2px, 2px);
    box-shadow: none;
}