*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root,
#page {
  height: 100%;
  width: 100%;
}

body {
  font-family: arial, sans-serif;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  font-size: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: bold;
  line-height: 1.2;
  color: inherit;
}
h1 {
  font-size: 1.802em;
  /*
  background: url(images/indent_h1.png) no-repeat left center;
  text-indent: 40px;
  */
}
h2 {
  font-size: 1.602em;
  /*
  background: url(images/indent_h2.png) no-repeat left center;
  text-indent: 30px;
  */
}
h3 {
  font-size: 1.424em;
  /*
  background: url(images/indent_h3.png) no-repeat left center;
  text-indent: 25px;
  */
}
h4 {
  font-size: 1.266em;
}
h5 {
  font-size: 1.125em;
}
h6 {
  font-size: 1em;
}

a {
  color: #333333;
  text-decoration: none;
}
a:visited {
  color: #333333;
}
a:hover {
  color: #ff3333;
}

p {
  font-size: 93%;
}

hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #cccccc;
  height: 1px;
}

ul,
ol {
  padding-left: 1.5em;
}

table {
  width: 100%;
}

td,
th {
  text-align: left;
  vertical-align: top;
}

th {
  padding: 3px;
  vertical-align: middle;
  font-weight: normal;
  color: #666;
  border-bottom: 1px solid #ccc;
  background: #eee;
}

tr.even td {
  padding: 2px;
}
tr.odd td {
  padding: 2px;
}
tr.even {
  color: inherit;
  background: #f7f7f7;
}
tr.odd {
  color: inherit;
  background: #fafafa;
}
tr.odd:hover,
tr.even:hover {
  color: #000;
  border-bottom: 1px solid #cacaca;
  background: #dfdfdf;
}
.outer {
  border: 1px solid #eee;
}

.head {
  padding: 5px;
  color: inherit;
  border-bottom: 1px solid #cacaca;
  background: #f2f2f2;
}

.even {
  padding: 2px;
  color: inherit;
  border-bottom: 1px solid #cacaca;
  background: #f7f7f7;
}

.odd {
  padding: 2px;
  color: inherit;
  border-bottom: 1px solid #cacaca;
  background: #fafafa;
}

.foot {
  padding: 5px;
  font-weight: bold;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: bold;
}
.font-italic {
  font-style: italic;
}

.block .blockTitle {
  height: 21px;
  margin-bottom: 0.4rem;
  padding: 0 10px 0 32px;
  color: #369;
  line-height: 21px;
  font-weight: bold;
}
.block .blockContent {
  padding: 0 10px;
}

#main {
  width: 902px;
  margin: 10px auto;
  line-height: 1.5;
  border: 1px solid #c2c7c7;
  background-color: #e2e7e7;
}

.mainContent {
  margin: 10px;
}

.block {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #c2c7c7;
  background-color: #fff;
}

#header {
  background: url(images/header_background.png) repeat-x;
}
#header .main-visual {
  margin: 0 auto;
  width: 902px;
  height: 175px;
  background-image: url(images/header_main.png);
}
#header .main-visual .title-link {
  display: inline-block;
  width: 420px;
  height: 100px;
}
#header .nav {
  margin: 0 auto;
  height: 40px;
  width: 902px;
}
#header .global-nav {
  float: left;
  margin: 0;
  padding: 0 2px;
  overflow: hidden;
  background: url(images/header_global-nav-separator.png) no-repeat;
}
#main .global-nav::after {
  content: "";
  display: block;
  clear: both;
}
#header .global-nav li {
  display: inline-block;
  height: 40px;
  padding: 0 2px 0 0;
  background: url(images/header_global-nav-separator.png) no-repeat right;
}
#header .global-nav li a {
  display: inline-block;
  height: 40px;
  padding: 0 8px;
  line-height: 40px;
}
#header .lang-nav {
  float: right;
  position: relative;
  top: 13px;
}
#header .lang-nav a {
  display: inline-block;
  height: 11px;
  background-image: url(images/header_lang-nav.png);
}
#header .lang-nav a.lang-nav-ja {
  width: 37px;
  background-position: 0 0;
}
#header .lang-nav a.lang-nav-ja:hover {
  background-position: 0 -11px;
}
#header .lang-nav a.lang-nav-en {
  width: 50px;
  background-position: -37px 0;
}
#header .lang-nav a.lang-nav-en:hover {
  background-position: -37px -11px;
}

#footer {
  margin: 0 auto;
  width: 902px;
}
#footer .copyright {
  float: left;
  font-size: 13px;
}
#footer .links {
  float: right;
}
#footer .links a {
  display: inline-block;
  margin: 0 5px;
}

#main a {
  text-decoration: underline;
}

#main section {
  margin: 1em 0.5em;
}

#main section p {
  margin-left: 5px;
  margin-right: 5px;
}

#main h2 {
  margin: 0.8rem 0 0.5rem;
  position: relative;
  padding-left: 20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: linear-gradient(#ffffff 0%, #eeeeee 100%);
  box-shadow: inset -1px -1px 0 rgba(255, 255, 255, 1);
  font-size: 123.1%;
  font-weight: bold;
  line-height: 37px;
  height: 37px;
}

#main h2::after {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  content: "";
  width: 2px;
  height: 24px;
  background-color: #c40018;
}

#main h3 {
  margin-left: 5px;
  background: url(images/h3-bullet.png) no-repeat left center;
  text-indent: 16px;
  line-height: 20px;
  padding: 0 0 5px 0;
  font-size: 108%;
  height: 24px;
  border-bottom: 3px double #cccccc;
}

#main .whatsnew::after {
  content: "";
  display: block;
  clear: both;
}
#main .whatsnew .title {
  float: left;
  width: 130px;
}
#main .whatsnew .title h3 {
  font-weight: 123.1%;
  line-height: 24px;
  border-bottom: none;
}
#main .whatsnew .news {
  float: left;
  border-left: 1px dotted #cccccc;
  width: calc(100% - 150px);
}
#main .whatsnew .title .more {
  margin: 0.3em 20px;
  padding-left: 5px;
  background: url(images/more-bullet.png) no-repeat left center;
}
#main .whatsnew .title .more a {
  color: #22528d;
}
#main .whatsnew .title .more a:hover {
  color: #ff3333;
}
#main .whatsnew .news ul {
  padding-left: 10px;
  width: 100%;
}
#main .whatsnew .news ul li {
  list-style: none;
  white-space: nowrap;
  height: 35px;
  line-height: 35px;
  border-top: 1px dotted #cccccc;
  font-size: 93%;
}
#main .whatsnew .news ul li:last-child {
  border-bottom: 1px dotted #cccccc;
}
#main .whatsnew .news ul li .date {
  display: inline-block;
  width: 75px;
}

#main .dbtools-top {
  margin: 5px;
}
#main .dbtools-top ul {
  padding-left: 0;
}
#main .dbtools-top ul li {
  list-style-type: none;
}
#main .dbtools-top .incf-japan-node {
  color: #22528d;
  font-weight: normal;
}

#main .dbtools-top h3 {
  margin: 0;
  padding: 0;
  text-indent: 0;
  background: none;
  border-bottom: none;
  line-height: 1.2em;
  font-size: 93%;
  height: auto;
}
#main .dbtools-top h3 a {
  text-decoration: none;
}
#main .dbtools-top h3 span.incf-japan-node {
  color: #ff0000;
  font-weight: bold;
}
#main .dbtools-top ul {
  margin: 10px 10px;
  overflow: hidden;
  zoom: 1;
}
#main .dbtools-top ul li {
  float: left;
  width: 265px;
  margin: 10px 5px;
}
#main .dbtools-top ul li:nth-child(3n + 1) {
  clear: left;
  margin-left: 0;
}
#main .dbtools-top ul li:first-child,
#main .dbtools-top ul li:nth-child(2),
#main .dbtools-top ul li:nth-child(3) {
  margin-top: 0;
}
#main .dbtools-top ul li .icon {
  float: left;
  width: 82px;
}
#main .dbtools-top ul li .info {
  float: right;
  width: 170px;
}
#main .dbtools-top ul li .info .detail {
  display: inline-block;
  margin-top: 5px;
}
#main .dbtools-top ul li .info .detail a,
#main .dbtools-top ul li .info .detail span {
  padding-left: 8px;
  background: url(images/more-bullet.png) no-repeat left center;
  font-size: 93%;
}
#main .dbtools-top ul li .info .detail a {
  color: #22528d;
  text-decoration: none;
}
#main .dbtools-top ul li .info .detail a:hover {
  color: #ff3333;
}
#main .dbtools-top .legend {
  margin: 0;
  text-align: right;
}
#main .dbtools-top .legend::before {
  content: "* ";
  color: #ff0000;
  font-weight: bold;
}

#main .dbtools .types::after {
  content: "";
  display: block;
  clear: both;
}
#main .dbtools .index .databases {
  float: left;
  width: 50%;
}
#main .dbtools .index .tools {
  float: right;
}
#main .dbtools .index section {
  margin: 0;
  padding: 0 10px;
  width: 50%;
}
#main .dbtools .index ul {
  padding-left: 5px;
}
#main .dbtools .index ul li {
  list-style-type: none;
  line-height: 1.2;
  padding-bottom: 5px;
}
#main .dbtools .legend {
  margin: 0;
  text-align: right;
}
#main .dbtools .legend::before {
  content: "* ";
  color: #ff0000;
  font-weight: bold;
}
#main .dbtools .incf-japan-node {
  color: #ff0000;
  font-weight: bold;
}
#main .dbtools .detail ul {
  padding-left: 0;
}
#main .dbtools .detail ul li {
  list-style-type: none;
  line-height: 1.2;
  padding-bottom: 5px;
  border-bottom: 1px dotted #cccccc;
}
#main .dbtools .item::after {
  content: "";
  display: block;
  clear: both;
}
#main .dbtools .item .icon {
  float: left;
  width: 100px;
}
#main .dbtools .item .info {
  float: right;
  width: 720px;
}
#main .dbtools .item .summary {
  margin: 0 0 0.5em;
}
#main .dbtools .contact,
#main .dbtools .url {
  margin: 0.5em 0;
}

#main .news .date {
  text-align: right;
  margin-right: 20px;
}

#main .about table {
  border-collapse: collapse;
}
#main .about td {
  border: 1px solid #999999;
  padding: 10px;
}
#main .about .date {
  font-weight: bold;
  text-align: right;
}

#main .publication h3.name {
  text-indent: 30px;
  font-size: 123.1%;
  line-height: 30px;
  height: 30px;
}
#main .publication h3.name.cdtdb {
  background: url(images/icon-cdt-db-24x17.png) no-repeat left center;
}
#main .publication h3.name.eeg {
  background: url(images/icon-multichannel-eeg-data-for-bmi-and-or-he-24x17.png) no-repeat left center;
}
#main .publication article {
  margin: 5px;
}
#main .publication h4.title {
  padding-left: 13px;
  background: url(images/h4-bullet.png) no-repeat left 5px;
  font-size: 108%;
  font-weight: bold;
}
#main .publication p.author,
#main .publication p.description,
#main .publication p.pmid {
  margin: 3px 0;
}
#main .publication p.pmid a {
  display: inline-block;
  padding: 0 4px;
  background-color: #999999;
  color: white;
  text-decoration: none;
}

#main .contact .division {
  font-weight: bold;
}
#main .contact .address {
  font-style: italic;
}

#main .page .summary {
  padding: 10px;
  background: #cccccc;
  border: 1px dotted #cccccc;
}
