.itemTypes .itemType {
    width: 50%;
    padding: 5px;
}

.itemTypes .itemType :global(table) {
    width: auto;
}

.itemTypes .itemType :global(table .itemTypeName) {
    vertical-align: middle;
    font-size: large;
}